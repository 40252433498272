import { z } from 'zod'
import {
    ContactType,
    ExpenseDocumentStatus,
    ExpenseDocumentType,
    TaxMethod,
} from '@prisma/client'
import { BaseEntitySchema } from '~/schemas/base/base-entity'
import {
    checkPaymentMethodOther,
    requiredString,
    requiredDateTime,
    requiredCurrencyCode,
    requiredUuid,
    optionalDateTime,
    requiredCountryCode,
} from '~/schemas/util'
import {
    CreatePaymentMethodSchema,
    PaymentMethodSchema,
    ContactSchema,
    FileSchema,
    CreateAddressSchema,
    UpdateContactSettingsSchema,
} from '~/schemas'
import {
    CreateExpenseLineItemSchema,
    ExpenseLineItemSchema,
} from './expense-line-item'

export const CreateExpenseSchema = z
    .object({
        type: z.nativeEnum(ExpenseDocumentType),
        contactId: requiredUuid(),
        displayNumber: requiredString(),
        issueDate: requiredDateTime(),
        taxableEventDate: requiredDateTime(),
        dueDate: optionalDateTime(),
        currencyCode: requiredCurrencyCode(),
        exchangeRate: z.number().default(1),
        notes: z.string().default(''),
        isVatRegistered: z.boolean(),
        taxMethod: z.nativeEnum(TaxMethod),
        status: z.nativeEnum(ExpenseDocumentStatus),
        lineItems: CreateExpenseLineItemSchema.array().min(1),
    })
    .extend(CreatePaymentMethodSchema.shape)
    .superRefine(checkPaymentMethodOther)

export const ExpenseSchema = BaseEntitySchema.extend({
    type: z.nativeEnum(ExpenseDocumentType),
    displayNumber: z.string(),
    issueDate: requiredDateTime(),
    dueDate: optionalDateTime(),
    taxableEventDate: requiredDateTime(),
    currencyCode: requiredCurrencyCode(),
    notes: z.string().default(''),
    subtotal: z.number(),
    subtotalInBaseCurrency: z.number(),
    taxTotal: z.number(),
    taxTotalInBaseCurrency: z.number(),
    total: z.number(),
    totalInBaseCurrency: z.number(),
    exchangeRate: z.number().default(1),
    isVatRegistered: z.boolean(),
    taxMethod: z.nativeEnum(TaxMethod),
    attachments: z.array(FileSchema),
    status: z.nativeEnum(ExpenseDocumentStatus),
    contact: ContactSchema,
    contactId: requiredUuid(),
    lineItems: ExpenseLineItemSchema.array().min(1),
}).extend(PaymentMethodSchema.shape)

export const ExpenseFromAIResponseSchema = z.object({
    // VALIDITY - Required initial check
    isValidInvoice: z
        .boolean()
        .describe(
            'TRUE if document structure matches invoice requirements, FALSE otherwise',
        ),

    // DOCUMENT STATUS
    status: z
        .nativeEnum(ExpenseDocumentStatus)
        .describe('Document processing status from ExpenseDocumentStatus enum'),
    type: z
        .nativeEnum(ExpenseDocumentType)
        .describe('Document classification from ExpenseDocumentType enum'),

    // CORE IDENTIFIERS
    displayNumber: z
        .string()
        .describe(
            'INVOICE NUMBER: Exact document identifier as shown on the document. Include all characters (letters, numbers, symbols)',
        ),

    // DATES - All dates must be in ISO 8601 (YYYY-MM-DD) format
    issueDate: z
        .string()
        .nullable()
        .describe('ISSUE DATE: When document was created. Format: YYYY-MM-DD'),
    dueDate: z
        .string()
        .nullable()
        .describe('DUE DATE: Payment deadline. Format: YYYY-MM-DD'),
    taxableEventDate: z
        .string()
        .nullable()
        .describe(
            'TAX EVENT DATE: When tax obligation occurs. Format: YYYY-MM-DD',
        ),

    // FINANCIAL PARAMETERS
    currencyCode: z
        .string()
        .describe(
            'CURRENCY: Three-letter code exactly matching ISO 4217 (example: USD, EUR, BGN)',
        ),
    total: z
        .number()
        .int()
        .describe(
            'TOTAL AMOUNT: Final sum in minor currency units (cents). Example: 10.25 BGN = 1025, 1550.99 BGN = 155099',
        ),

    // PAYMENT DETAILS
    paymentMethod: PaymentMethodSchema.shape.paymentMethod.describe(
        'HOW PAID: Payment method from available options in PaymentMethodSchema',
    ),

    // TAX CALCULATION METHOD
    taxMethod: z
        .nativeEnum(TaxMethod)
        .describe(
            'TAX CALCULATION METHOD - Select one:\n' +
                '1. PER_TOTAL = Tax applied to final sum\n' +
                '2. PER_LINE = Tax calculated per line item\n' +
                '3. NO_TAX = No tax applied to document',
        ),

    // SUPPLIER/ISSUER DETAILS
    contact: z
        .object({
            type: z.literal(ContactType.SUPPLIER).array(),
            name: z
                .string()
                .describe(
                    'SUPPLIER NAME: Full legal name of document issuer exactly as shown',
                ),
            email: z
                .string()
                .describe('SUPPLIER EMAIL: Complete email address'),
            uic: z
                .string()
                .describe(
                    'BUSINESS ID: Company registration number or unified ID code',
                ),
            vat: z
                .string()
                .describe('TAX ID: VAT/tax number without country prefix'),
            countryCode: z
                .string()
                .describe(
                    'COUNTRY CODE: Two-letter code from ISO 3166-1 (example: BG, US)',
                ),
            defaultCurrencyCode: z
                .string()
                .describe(
                    'DEFAULT CURRENCY: Three-letter code from ISO 4217, must match document currency',
                ),
            address: z
                .object({
                    country: z
                        .string()
                        .describe(
                            'COUNTRY CODE: Two-letter code from ISO 3166-1',
                        ),
                    // Address components - use empty string if not in that language
                    line1_bg: z
                        .string()
                        .describe(
                            'STREET ADDRESS (Bulgarian): First line if in Bulgarian, empty string if in English',
                        ),
                    line1_en: z
                        .string()
                        .describe(
                            'STREET ADDRESS (English): First line if in English, empty string if in Bulgarian',
                        ),
                    line2_bg: z
                        .string()
                        .describe(
                            'STREET ADDRESS 2 (Bulgarian): Second line if in Bulgarian, empty string if in English',
                        ),
                    line2_en: z
                        .string()
                        .describe(
                            'STREET ADDRESS 2 (English): Second line if in English, empty string if in Bulgarian',
                        ),
                    city_bg: z
                        .string()
                        .describe(
                            'CITY (Bulgarian): If in Bulgarian, empty string if in English',
                        ),
                    city_en: z
                        .string()
                        .describe(
                            'CITY (English): If in English, empty string if in Bulgarian',
                        ),
                    municipality_bg: z
                        .string()
                        .describe(
                            'MUNICIPALITY (Bulgarian): If in Bulgarian, empty string if in English',
                        ),
                    municipality_en: z
                        .string()
                        .describe(
                            'MUNICIPALITY (English): If in English, empty string if in Bulgarian',
                        ),
                    state_bg: z
                        .string()
                        .describe(
                            'STATE (Bulgarian): If in Bulgarian, empty string if in English',
                        ),
                    state_en: z
                        .string()
                        .describe(
                            'STATE (English): If in English, empty string if in Bulgarian',
                        ),
                    attention_bg: z
                        .string()
                        .describe(
                            'ATTENTION (Bulgarian): If in Bulgarian, empty string if in English',
                        ),
                    attention_en: z
                        .string()
                        .describe(
                            'ATTENTION (English): If in English, empty string if in Bulgarian',
                        ),
                    postalCode: z.string(),
                    phone: z.string(),
                })
                .describe(
                    'ADDRESS: In original language only - do not translate or transliterate. Use empty string for absent language',
                ),
        })
        .describe(
            'SUPPLIER INFORMATION: Complete details of document issuer including identification and address',
        ),

    // LINE ITEM
    lineItem: z
        .object({
            accountId: z
                .string()
                .describe(
                    'ACCOUNT REFERENCE: Most appropriate account ID from company.accounts',
                ),
            description: z
                .string()
                .describe(
                    "SUMMARY: Must be in document's original language. For Bulgarian documents use Bulgarian, for English documents use English. DO NOT translate.",
                ),
            preTaxPrice: z
                .number()
                .int()
                .describe(
                    'TOTAL PRE-TAX AMOUNT: Sum in minor currency units (cents)',
                ),
            taxId: z
                .string()
                .nullable()
                .describe(
                    'PRIMARY TAX REFERENCE: Most applicable tax ID from company.taxes, or null if no tax',
                ),
        })
        .describe(
            'CONSOLIDATED EXPENSE: Single line item summarizing entire document',
        ),

    // ADDITIONAL NOTES
    notes: z
        .string()
        .describe(
            'EXTRA INFORMATION: Any additional text or notes found on document',
        ),
})

export const OcrExpenseAttachmentSchema = z
    .object({
        type: z.nativeEnum(ExpenseDocumentType),
        contact: z.object({
            id: requiredUuid().optional(),
            type: z.nativeEnum(ContactType).array(),
            name: requiredString().catch(() => ''),
            uic: z.string().catch(() => ''),
            vat: z.string().catch(() => ''),
            email: z
                .string()
                .email()
                .catch(() => ''),
            countryCode: requiredCountryCode().catch(() => ''),
            defaultCurrencyCode: requiredCurrencyCode().catch(() => ''),
            address: CreateAddressSchema.partial(),
            settings: UpdateContactSettingsSchema,
        }),
        contactId: requiredUuid().optional(),
        displayNumber: z.string().optional(),
        issueDate: requiredDateTime()
            .optional()
            .catch(() => undefined),
        taxableEventDate: requiredDateTime()
            .optional()
            .catch(() => undefined),
        dueDate: optionalDateTime()
            .optional()
            .catch(() => undefined),
        currencyCode: requiredCurrencyCode()
            .optional()
            .catch(() => undefined),
        notes: z
            .string()
            .optional()
            .catch(() => undefined),
        taxMethod: z
            .nativeEnum(TaxMethod)
            .optional()
            .catch(() => undefined),
        status: z
            .nativeEnum(ExpenseDocumentStatus)
            .optional()
            .catch(() => undefined),
        lineItems: CreateExpenseLineItemSchema.array()
            .min(1)
            .catch(() => []),
        total: z.number(),
    })
    .extend(CreatePaymentMethodSchema.shape)
    .extend({
        inconsistencyWarning: z.boolean().optional(),
    })

export type CreateExpenseRequest = z.infer<typeof CreateExpenseSchema>
export type UpdateExpenseRequest = z.infer<typeof CreateExpenseSchema>
export type ExpenseResponse = z.infer<typeof ExpenseSchema>
export type ExpenseFromAIResponse = z.infer<typeof ExpenseFromAIResponseSchema>
export type OcrExpenseAttachmentResponse = z.infer<
    typeof OcrExpenseAttachmentSchema
>
